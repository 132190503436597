<template>
  <v-container id="category-table" fluid tag="section">
    <base-material-card
      icon="mdi-chart-bar"
      inline
      color="secondary"
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h5">
          <b>{{ $t("stock_history_single") }}</b>
        </h1></template
      >

      <!-- button para voltar -->
      <v-btn
        color="secondary"
        @click="() => handleBack()"
        rounded
        absolute
        fab
        top
        right
      >
        <v-icon large>mdi-arrow-left</v-icon>
      </v-btn>

      <!-- tabela com histórico da ação -->
      <v-progress-circular
        v-if="loading"
        style="margin-left: 50%"
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
      <v-simple-table v-else>
        <thead>
          <tr>
            <th>{{ $t("name") }}</th>
            <th>{{ $t("description") }}</th>
            <th>{{ $tc("stock", 1) }}</th>
            <th>{{ $t("date") }}</th>
            <th>{{ $t("stocks") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(stock, i) in stockTypeHistoryData" :key="i">
            <td>
              {{ stock.stockTypeName }}
              <v-chip class="ma-2" small v-if="stock.useValue" color="primary">
                {{ $t("use_stock_label") }}
              </v-chip>
            </td>
            <td>
              {{ stock.stockTypeDescription }}
            </td>
            <td>
              {{ formatCurrency(stock.currency, stock.value, 2, 8) }}
            </td>
            <td>
              {{ formatDate(stock.date) }}
            </td>
            <td>
              <v-btn
                @click="openStockDialog(stock)"
                class="px-2 ml-1 secondary"
                min-width="0"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                @click="() => deleteStock(stock)"
                class="px-2 ml-1 error"
                color="red"
                min-width="0"
                small
                >
                <v-icon small>mdi-trash-can</v-icon>
              </v-btn>              
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>

    <v-dialog v-model="openDialogEditStock" v-if="openDialogEditStock">
      <AddStockToVehicleModal
        :vehicleInfo="vehicleInfo"
        :stockEdit="stockEdit"
        :editByHistoryTable="true"
        @close="openDialogEditStock = false"
        @reloadStocks="() => getStockTypeHistory()"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { formatDate } from "@/shared/helpers/dateHelper";
import AddStockToVehicleModal from "../addVehicle/steps/AddStockToVehicleModal";
import { vehicleTabEnum } from "../enum/vehicleTabEnum";

export default {
  name: "StockTypeHistory",
  components: {
    AddStockToVehicleModal,
  },
  data() {
    return {
      stockTypeId: null,
      apiService: new ApiService(),
      vehicleId: null,
      stockTypeHistoryData: [],
      formatCurrency,
      openDialogEditStock: false,
      formatDate,
      stockEdit: null,
      loading: false,
      vehicleTabEnum,
      vehicleInfo: null,
    };
  },
  async created() {
    this.stockTypeId = this.$route.params.stockTypeId;
    this.vehicleId = this.$route.params.vehicleId;

    if (this.stockTypeId && this.vehicleId) {
      this.vehicleInfo = { id: this.vehicleId };
      await this.getStockTypeHistory();
    }
  },
  methods: {
    openStockDialog(stock) {
      this.stockEdit = stock;
      this.openDialogEditStock = true;
    },
    handleBack() {
      this.$router.push(
        `/vehicle/edit/${this.vehicleId}?tab=${this.vehicleTabEnum.vehicleStocks}`
      );
    },
    async getStockTypeHistory() {
      this.loading = true;

      await this.apiService
        .getRequest(
          `investmentvehicle/vehiclestockvalue/${this.vehicleId}/${this.stockTypeId}/history`
        )
        .then((resp) => {
          this.stockTypeHistoryData = resp.content;
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });

      this.loading = false;
    },

    async deleteStock(stock) {
      this.loading = true;
      await this.apiService
        .deleteRequest(
          `investmentvehicle/stockHistory/${stock.id}`
        )
        .then(() => {
          this.getStockTypeHistory();
        })
        .catch((err) => {
          if (err.body.statusCode === 400) {
            this.$toast.error(this.$t(err.body.message));
          } else {
            this.$toast.error(this.$t("an_error_ocurred"));
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

  },
};
</script>

<style scoped></style>
